import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

//import ReactPlayerLoader from '@brightcove/react-player-loader';

import ImageHero from "../images/hero-books.png"
import ImageLineCircles from "../images/references-lines-circles.png"
import ImageDownloadThumb from "../images/resources-downloadable-resources-about-pmr.png"
import UnderstandingPmrPdf from "../pdf/Understanding_PMR_Brochure_MAT-US-2207359.pdf"
import IconListenHere from "../images/icon-listen-here.svg"
import GlobalRefBg from "../images/resources-bg-references.png"
import VideoBgBottom from "../images/resources-bg-video.png"
import ReactAudioPlayer from 'react-audio-player';


import downloadableResourcePathophysiologyPMR from "../images/downloadable-resource-pathophysiology-PMR.png"

import downloadableResourcePMRManagement from "../images/downloadable-resource-PMR-management.png"

import downloadableResourceGlucocorticoidUseRheumDisorders from "../images/downloadable-resource-glucocorticoid-use-rheum-disorders.png"

import AudioTranscriptTest from "../components/audioTranscriptTest";

const Resources = () => {

  // const vp = `<video-js data-account="3845398857001" data-player="default" data-embed="default" controls="" data-video-id="6312867406112" data-playlist-id="" data-application-id="" width="300" height="150" class="vjs-fluid"></video-js>`;

  // const videoSuccess =(success)=>{
  //   let el = success.ref.el_;
  //   el.setAttribute("width", 300);
  //   el.setAttribute("height", 150);
  //   el.classList.add('vjs-fluid');
  // }

  useEffect(() => {
    // Load Vimeo player script
    const script = document.createElement("script");
    script.src = "https://player.vimeo.com/api/player.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Clean up the script when the component is unmounted
      document.body.removeChild(script);
    };
  }, []);


  const [showAudioPlayer, setShowAudioPlayer] = useState(false);
  const [videoDetails, setVideoDetails] = useState({ src: `/MAT-US-2208630_DrCalabresePMRPodcast_FINAL.mp3`, title: `Default`, subTitle: `Default sub (5:30)` });

  const toggleAudio = (e, setDetails = false) => {
    if (!setDetails) {
      //console.log('details not provided, just show hide');
      setShowAudioPlayer(!showAudioPlayer);
      document.querySelector('html').style.overflow = 'unset';
    } else {
      //console.log('details shared, once set, useEffect takes over');
      let title = e.currentTarget.dataset.title;
      let subTitle = e.currentTarget.dataset.subtitle;
      let src = e.currentTarget.dataset.src;
      let slug = e.currentTarget.dataset.slug;
      setShowAudioPlayer(true);
      setVideoDetails({ src: src, title: title, slug: slug, subTitle: subTitle });
      document.querySelector('html').style.overflow = 'hidden';
    }
    e.preventDefault();
  }

  useEffect(() => {
    if (!showAudioPlayer) {
      console.log('initial or close hide');
    } else {
      console.log('show hide once details propagated');
      setShowAudioPlayer(true);
    }
  }, [setVideoDetails]);

  useEffect(() => {
    let w = window.location.href,
      hv = (window.outerWidth < 768) ? 120 : 250, // video margin top height
      hp = (window.outerWidth < 768) ? 0 : 120; // podcast margin top height
    if (w.indexOf('#video-1') !== -1) window.scrollTo(0, ((document.getElementById('video-1').offsetTop) - hv));
    if (w.indexOf('#video-2') !== -1) window.scrollTo(0, ((document.getElementById('video-2').offsetTop) - hv));
    if (w.indexOf('#podcast-1') !== -1) window.scrollTo(0, ((document.getElementById('podcast-1').offsetTop) - hp));
  });

  return (
    <Layout pageid="resources">
      <Seo title="Resources | PMR and IL-6 HCP Website" description="Downloadable resources, podcasts, and videos about IL-6 and PMR." ogDesc="Downloadable resources, podcasts, and videos about IL-6 and PMR." lang="en-US" />

      {showAudioPlayer &&
        <div className="audio-modal" >
          <div className="audio-modal__sneeze" onClick={(e) => toggleAudio(e)}></div>
          <div className="audio-modal__inner styled-scrollbars">
            <button onClick={(e) => toggleAudio(e)} className="audio-modal__close">+ <span className="visually-hidden">Close Audio Player</span></button>
            <h4 className="section-subhead">{videoDetails.title}</h4>
            <p>{videoDetails.subTitle}</p>
            <ReactAudioPlayer
              src={videoDetails.src}
              autoPlay={true}
              controls
              style={{ width: `100%`, marginTop: `1rem` }}
            />

            <AudioTranscriptTest slug={videoDetails.slug} />

          </div>
        </div>
      }

      <div id="hero-image-container" className="mb-7 md_mb-8 lg_mb-10">
        <h1>RESOURCES</h1>
        <img src={ImageHero} alt="books" id="hero-resources" />
      </div>

      <div id="resources-learn-more" className="mb-8 md_mb-8">
        <h2 className="section-headline">Learn more about the role of IL-6 in PMR with these helpful resources:</h2>
      </div>


      <div id="resources-downloadable" className="mt-2 mb-10 md_mb-10">
        <div id="resources-image-download-thumb" className="collapsed">
          <img src={ImageDownloadThumb} alt="downloadable resources about PMR" /></div>
        <div className="resources-gray-container download">
          <p className="section-subhead mb-2 md_mb-2">Downloadable Resource About PMR</p>
          <p>This brochure provides an in-depth and clinical perspective on IL-6 and PMR</p>
        </div>
        <div className="resources-button-container">
          <a href="/PMRDSEDigitalBrochure.pdf" target="_blank" rel="noreferrer noopener"><button>DOWNLOAD</button></a>
        </div>
      </div>

      <div className="row" style={{ marginTop: '5%', marginBottom: '6%', maxWidth: 'none' }}>
        <div className="columns small-12 medium-12 large-4 show-for-large"></div>
        <div className="columns small-12 medium-12 large-8">
          <div id="resources-downloadable" className="mb-10 md_mb-10">
            <div id="resources-image-download-thumb" className="update"><img src={downloadableResourcePathophysiologyPMR} alt="downloadable resource on pathophysiology of PMR" className="thumb-update" /></div>
            <div className="resources-gray-container download">
              <p className="section-subhead mb-2 md_mb-2">Downloadable Resource About Pathophysiology of PMR</p>
              <p>This brochure details the causes of PMR, with a specific focus on IL-6</p>
            </div>
            <div className="resources-button-container"><a href="/PMRPathophysiologyandIL-6Monograph.pdf" target="_blank" rel="noreferrer noopener"><button>DOWNLOAD</button></a></div>

          </div>
        </div>
      </div>


      <div id="resources-downloadable" className="mb-10 md_mb-10">
        <div id="resources-image-download-thumb" className="update">
          <img src={downloadableResourcePMRManagement} alt="downloadable resource about PMR management" className="thumb-update" /></div>
        <div className="resources-gray-container download">
          <p className="section-subhead mb-2 md_mb-2">Downloadable Resource About PMR Management</p>
          <p>This brochure provides details on the facts and challenges of PMR diagnosis and management</p>
        </div>
        <div className="resources-button-container">
          <a href="/PMRManagementMonograph.pdf" target="_blank" rel="noreferrer noopener"><button>DOWNLOAD</button></a>
        </div>
      </div>

      <div className="row" style={{ marginTop: '5%', marginBottom: '6%', maxWidth: 'none' }}>
        <div className="columns small-12 medium-12 large-4 show-for-large"></div>
        <div className="columns small-12 medium-12 large-8">
          <div id="resources-downloadable" className="mb-10 md_mb-10">
            <div id="resources-image-download-thumb" className="update"><img src={downloadableResourceGlucocorticoidUseRheumDisorders} alt="downloadable resource on glucocorticoid use in rheumatic disorders" className="thumb-update" /></div>
            <div className="resources-gray-container download">
              <p className="section-subhead mb-2 md_mb-2">Downloadable Resource About Glucocorticoid Use in Rheumatic Disorders</p>
              <p>This brochure details the challenges of using GCs in rheumatic disorders such as PMR</p>
            </div>
            <div className="resources-button-container"><a href="/GCUseandGTIScoreMonograph.pdf" target="_blank" rel="noreferrer noopener"><button>DOWNLOAD</button></a></div>

          </div>
        </div>
      </div>

      <div id="video-1" className="row" style={{ marginTop: '5%', marginBottom: '6%', maxWidth: 'none' }}>

        <div className="columns small-12 medium-12 large-9">

          <div className="mb-6 resources-video-2">
            <div className="bc-video-embed-container">
              <div className="bc-video">
                <div style={{ padding: "56.21% 0 0 0", position: "relative" }}>
                  <iframe
                    src="https://player.vimeo.com/video/949571707?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                    style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                    title="The Pathophysiology of PMR and the Role of Elevated IL-6 in PMR"
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="resources-gray-container video"><p className="section-subhead mb-2 md_mb-2">Video About the Pathophysiology of PMR and the Role of Elevated IL-6 in PMR</p></div>
            <div className="resources-button-container">
              <button onClick={(e) => toggleAudio(e, true)} data-title="Understanding PMR Pathophysiology, an Expert's Perspective" data-slug="calabrese" data-subtitle="Dr Leonard Calabrese | 5:30" data-src="/MAT-US-2208630_DrCalabresePMRPodcast_FINAL.mp3" style={{ padding: '15px 50px 12px 45px' }}><img src={IconListenHere} />&nbsp;&nbsp;&nbsp;LISTEN HERE</button>
              <AudioTranscriptTest slug="calabrese" />
            </div>
          </div>

        </div>

        <div className="columns small-12 medium-12 large-3 show-for-large text-right"><img src={ImageLineCircles} alt="" style={{ width: '25%' }} /></div>
      </div>

      <div id="video-2" className="row" style={{ marginTop: '12%', marginBottom: '10%', maxWidth: 'none' }}>

        <div className="columns small-12 medium-12 large-9">

          <div className="mb-6 resources-video-2">
            <div className="bc-video-embed-container">
              <div className="bc-video">
                <div style={{ padding: "56.21% 0 0 0", position: "relative" }}>
                  <iframe
                    src="https://player.vimeo.com/video/949575142?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                    style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                    title="The Pathophysiology of PMR and the Role of Elevated IL-6 in PMR"
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="resources-gray-container video">
              <p className="section-subhead mb-2 md_mb-2">Video About the Challenges and Opportunities in PMR Management</p>
            </div>
            <div className="resources-button-container">
              <button onClick={(e) => toggleAudio(e, true)} data-title="PMR Management: Challenges and Opportunities" data-slug="stone" data-subtitle="Dr John Stone, MD, MPH | 7:00" data-src="/MAT-US-2300194_DrStonePMRPodcast_ForResubmission.mp3" style={{ padding: '15px 50px 12px 45px' }}><img src={IconListenHere}  />&nbsp;&nbsp;&nbsp;LISTEN HERE</button>
              <AudioTranscriptTest slug="stone" />
            </div>
          </div>

        </div>
        <div className="columns small-12 medium-12 large-3 show-for-large"></div>
      </div>

      <div className="row" style={{ marginTop: '5%', marginBottom: '2.5%', maxWidth: 'none' }}>

        <div className="columns small-12 medium-12 large-4 show-for-large" style={{ textAlign: 'right' }}></div>
        <div className="columns small-12 medium-12 large-8" id="podcast-1">

          <div className="resources-reading mb-6">
            <div className="resources-gray-container video">
              <p className="section-subhead mb-2 md_mb-2">Reading the Rheum: What You Need to Know About PMR</p>
              <p>Dr Anisha Dua, MD, MPH and Ms Amanda Mixon, <span className="nowrap">PA-C</span> discuss the clinical approaches and considerations needed to determine the diagnosis for patients who present with PMR<br /><br /></p>
            </div>
            <div className="resources-button-container">
              <button onClick={(e) => toggleAudio(e, true)} data-title="Reading the Rheum: Let's Talk About PMR Managagement" data-slug="rheum" data-subtitle="Dr Anisha Dua, MD, MPH and Ms Amanda Mixon, PA-C | 7:00" data-src="/Sanofi_ProHealth_Kevzara_ReadingtheRheum_Audio_WithoutReachMD.mp3" style={{ padding: '15px 50px 12px 45px' }}><img src={IconListenHere} alt="reading the rheum: what you need to know about PMR audio" />&nbsp;&nbsp;&nbsp;LISTEN HERE</button>
              <AudioTranscriptTest slug="rheum" />
            </div>
          </div>

        </div>
      </div>

      <div><img src={GlobalRefBg} alt="" style={{ width: '90%', maxWidth: '800px' }} /></div>

    </Layout>
  )
}

export default Resources
